td{
    vertical-align: top;
}
.img-div-admin{
    max-width: 450px;
}
.img-div-admin img,
    .img-div-admin video{
    max-width: 200px;
    margin: 0 5px 5px 0;
    cursor: pointer;
    transition: .5s;
}

.img-div-admin img:hover,
    .img-div-admin video:hover{
        transform: scale(.9);
        transition: .5s;
        box-shadow: 0 0 20px 2px green;
}