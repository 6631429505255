@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify{
  position: fixed;
  z-index: 999999999 !important;
}

img{
  width: 100%;
}

html{
  scroll-behavior: smooth;
}

body,
button {
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
}


.form-sidebar .img{
  position: relative;
  overflow: hidden;
  height: 320px;
  width: 320px;
  border-radius: 50%;
  background: rgb(12, 112, 12);
  padding: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-sidebar img{
  border-radius: 50%;
  height: 100%;
}

.form-sidebar .img:hover label{
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.383);
}

.form-sidebar .img label{
  cursor: pointer;
  position: absolute;
  padding: 4px;
  color: white;
  border-radius: 50%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 35px;  
}

.form-sidebar .img input{
  display: none !important;
}

.form-product-sidebar{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-cover-img:hover .cover-image-input-label{
  display: flex;
}

.product-cover-img .cover-image-input-label{
  background: rgba(17, 17, 17, 0.534);
  width: 100%;
  height: 100%;
  padding: 10px;
  color: white;  
  border-radius: 5px;
  position: absolute;
  display: none;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.product-cover-img .cover-image-input-label i{
  background: black;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 2px;
  border: 3px solid white;
}

.product-cover-img{
  width: 300px;
  position: relative;
}

.upload-button.disabled{
  background: grey;
  cursor: not-allowed;
}

.upload-button{
  width: 300px;
  background: green;
  padding: 5px 10px;
  text-transform: uppercase;
  font-weight: bolder;
  border-radius: 5px;
  color: white;
  border: none;
  margin: 10px 0;
}

.product-cover-img img{
  padding: 0;
  border-radius: 5px;
  max-width: 100%;
  max-height: 400px;
  width: 300px;
  height: 400px;
  /* border: 3px dotted black; */
  border-radius: 5px;
}
